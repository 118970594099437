import { createApp } from "vue";
import App from "./App.vue";
import VueCookies from "vue-cookies";
import CwLogin from "./components/CwLogin.vue";
import CwResetPassword from "./components/CwResetPassword.vue";
import { createRouter, createWebHashHistory } from "vue-router";
import { datadogRum } from "@datadog/browser-rum";
import extendValidationRules from "@/validation/extend-validation-rules";

const routes = [
    {
        path: "/",
        component: CwLogin,
    },
    {
        path: "/resetPassword",
        component: CwResetPassword,
    },
];

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes,
});

// Extend vee-validate validation rules
extendValidationRules();

fetch("/app.config.json", {
    cors: true,
})
    .then(function (response) {
        if (!response.ok) {
            // show error screen
            throw Error(response.statusText);
        }
        return response;
    })
    .then((data) => data.json())
    .then((data) => {
        window.APP_CONFIG = data;
        if (data.hasOwnProperty("datadogLogin")) {
            datadogRum.init({
                applicationId: data.datadogLogin.applicationId,
                clientToken: data.datadogLogin.clientToken,
                site: "datadoghq.com",
                service: "voluum-login",
                env: data.environment,
                // Specify a version number to identify the deployed version of your application in Datadog
                version: import.meta.env.VITE_VERSION,
                sessionSampleRate: data.datadogLogin.sessionSampleRate,
                trackUserInteractions: true,
                trackResources: true,
                defaultPrivacyLevel: "mask-user-input",
                beforeSend: function (event) {
                    // Discard errors from the browser extension
                    if (event.type === "error" && event.error.stack?.includes("chrome-extension://")) {
                        return false;
                    }

                    return true;
                },
            });
        }

        const app = createApp(App).use(router).use(VueCookies);

        app.config.errorHandler = function (error) {
            datadogRum.addError(error);

            // For development and SG purposes
            console.error(error);
        };

        app.mount("#app");
    });
