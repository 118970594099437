import { required, email, digits, min, max, regex } from "@vee-validate/rules";
import { defineRule } from "vee-validate";
import { hasAnyCharacterFromSequence, hasConsecutiveCharactersSequence, hasRepeatedCharacters } from "@/validation/helper";
import { AlphabeticalSequence, NumericalSequence, SpecialCharactersSequence, USQwertySequence } from "@/validation/data";

export default () => {
    defineRule("required", (value) => required(value) || "This field is required.");
    defineRule("email", (value) => email(value) || "This field must be a valid email.");

    defineRule("digits-length", (value, [len]) => digits(value, [len]) || `This field must contain ${len} digits.`);
    defineRule("min", (value, [len]) => min(value, [len]) || `This field must contain at least ${len} characters.`);
    defineRule("max", (value, [len]) => max(value, [len]) || `This field must contain at most ${len} characters.`);
    defineRule(
        "min-max",
        (value, [lenMin, lenMax]) =>
            (!!value && min(value, [lenMin]) && max(value, [lenMax])) ||
            `This field must contain between ${lenMin} and ${lenMax} characters.`,
    );
    defineRule(
        "hasUppercaseLetter",
        (value) => (!!value && regex(value, [/[A-Z]/])) || "This field must contain at least one uppercase letter.",
    );
    defineRule(
        "hasLowercaseLetter",
        (value) => (!!value && regex(value, [/[a-z]/])) || "This field must contain at least one lowercase letter.",
    );
    defineRule("hasDigit", (value) => (!!value && regex(value, [/\d/])) || "This field must contain at least one digit.");

    defineRule(
        "hasSpecialCharacter",
        (value) =>
            hasAnyCharacterFromSequence(value, SpecialCharactersSequence) || "This field must contain at least one special character.",
    );
    defineRule(
        "hasNotAlphabeticalSequence",
        (input, [minSequenceLength]) =>
            !hasConsecutiveCharactersSequence(input, AlphabeticalSequence, +minSequenceLength, false) ||
            `This field cannot contain ${minSequenceLength} or more consecutive letters.`,
    );
    defineRule(
        "hasNotDigitsSequence",
        (input, [minSequenceLength]) =>
            !hasConsecutiveCharactersSequence(input, NumericalSequence, +minSequenceLength) ||
            `This field cannot contain ${minSequenceLength} or more consecutive digits.`,
    );
    defineRule(
        "hasNotUSKeyboardSequence",
        (input, [minSequenceLength]) =>
            !hasConsecutiveCharactersSequence(input, USQwertySequence, +minSequenceLength, false) ||
            `This field cannot contain ${minSequenceLength} or more consecutive US keyboard characters.`,
    );
    defineRule(
        "hasNotRepeatedCharacters",
        (input, [minSequenceLength]) =>
            !hasRepeatedCharacters(input, +minSequenceLength) || `This field cannot contain ${minSequenceLength} repeated characters.`,
    );
};
