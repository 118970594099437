import http from "../helpers/http";

export default {
    setupAnalytics: function () {
        let gtmId = window.APP_CONFIG.analytics && window.APP_CONFIG.analytics.tagManagerID;

        if (!gtmId) {
            return;
        }

        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", gtmId);

        function initDataLayer(eeaStatus) {
            window.dataLayer.push({
                event: "initialDataLayerData",
                b9e1a58e5807: eeaStatus,
            });
        }

        http.get("https://1706.codewise.com").then(
            (response) => {
                initDataLayer(response.data.b9e1a58e5807);
            },
            () => {
                initDataLayer(2);
            },
        );
    },

    pushDataLayerEvent: function (event, label, category, value) {
        let dataLayerData = {};
        dataLayerData.event = event || "updateDataLayer";
        dataLayerData.eventLabel = label || null;
        dataLayerData.eventCategory = category || null;
        dataLayerData.eventValue = value || null;
        if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push(dataLayerData);
        }
    },
};
