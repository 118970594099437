<template>
    <div class="login-body">
        <VeeForm v-if="!showMfa" v-slot="{ values, handleSubmit }" as="div" class="login-content" :validation-schema="schema">
            <form @submit="handleSubmit($event, submitForm)">
                <div class="msg-panel" @click="handleNotificationClick">
                    <div v-if="errorMessage" data-cy="error-message-panel" class="msg error">
                        <div class="icon-wrapper">
                            <i class="material-icons" tabindex="-1">error_outline</i>
                        </div>
                        <div class="message" v-html="errorMessage"></div>
                    </div>
                    <div v-if="infoMessage && !errorMessage" class="msg info">
                        <div class="icon-wrapper">
                            <i class="material-icons" tabindex="-1">check_circle_outline</i>
                        </div>
                        <div class="message">{{ infoMessage }}</div>
                    </div>
                    <div v-if="maintenanceNote && !errorMessage && !infoMessage" class="msg maintenance">
                        <div class="icon-wrapper">
                            <i class="material-icons" tabindex="-1">error_outline</i>
                        </div>
                        <div class="message">{{ maintenanceNote }}</div>
                    </div>
                </div>
                <div class="login-header">Welcome, please sign in!</div>
                <CwInput
                    id="email"
                    cy="email"
                    autofocus
                    placeholder="Email"
                    type="email"
                    gtm="login_input"
                    autocomplete="email"
                    name="email"
                    input-mode="email"
                    :initValue="loginDetails.email"
                    @keyup.enter="handleSubmit($event, submitForm)"
                />
                <CwInput
                    id="password"
                    cy="password"
                    placeholder="Password"
                    type="password"
                    gtm="password_input"
                    autocomplete="password"
                    name="password"
                    input-mode="password"
                    @keyup.enter="handleSubmit($event, submitForm)"
                />
                <a
                    ><router-link data-cy="reset-password-link" :to="{ path: 'resetPassword', query: { email: values.email } }"
                        >Forgot your password?</router-link
                    ></a
                >
                <CwButton
                    id="submitForm"
                    cy="submitForm"
                    testid="submitForm"
                    class="sign-in-btn"
                    gtm="trkregform_createaccountCTA"
                    label="SIGN IN"
                    :is-loader="isRequestPending"
                    @click="handleSubmit($event, submitForm)"
                >
                </CwButton>
                <div class="oauth-separator">
                    <div class="label">or</div>
                </div>
                <div v-if="showGoogleBtn" class="google-sign-in" @click="googleLogin()">Sign in with Google</div>
                <div class="no-account-info">
                    {{ version.noAccountLabel }}
                    <a :href="version.noAccountLink">{{ version.noAccountLinkText }}</a>
                </div>
            </form>
        </VeeForm>
        <VeeForm v-if="showMfa" v-slot="{ handleSubmit }" as="div" :validation-schema="schemaMfa">
            <div class="msg-panel"></div>
            <div class="login-content">
                <div class="login-header mfa-header">Almost there...</div>
                <div class="subheader">Please enter six-digit code from the application.</div>
                <CwInput
                    id="mfa"
                    cy="mfa"
                    placeholder="Six-digit code"
                    type="input"
                    gtm="mfa_input"
                    name="mfa"
                    input-mode="input"
                    @keyup.enter="handleSubmit($event, submitMfaForm)"
                />
                <CwButton
                    id="submitMfaForm"
                    cy="submit-mfa"
                    gtm="trkregform_createaccountCTA"
                    label="SIGN IN"
                    :is-loader="isRequestPending"
                    @click="handleSubmit($event, submitMfaForm)"
                >
                </CwButton>
                <div class="row">
                    <CwCheckbox id="rememberDeviceCheckbox" v-model="loginDetails.rememberDevice" gtm="trkregform_commercialcheckbox">
                        Remember me
                    </CwCheckbox>
                    <CwTooltip
                        tooltip-text="This device will still require a password to sign in but an additional security code will not be required for 30 days."
                    >
                    </CwTooltip>
                </div>
                <div class="no-account-info">
                    Issues while signing in?
                    <a href="https://doc.voluum.com/#support" target="_blank">Contact us</a>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script setup>
import CwInput from "./CwInput.vue";
import CwTooltip from "./CwTooltip.vue";
import CwButton from "./CwButton.vue";
import CwCheckbox from "./CwCheckbox.vue";
import LoginService from "../services/LoginService";
import AnalyticsService from "../services/AnalyticsService";
import { inject, reactive, ref, onMounted, onBeforeMount } from "vue";
import { Form as VeeForm } from "vee-validate";
import LoginVersionService from "../services/LoginVersionService";

const $cookies = inject("$cookies");

const schema = {
    email: "required|email",
    password: "required",
};
const schemaMfa = {
    mfa: "required|digits-length:6",
};

// https://codewise.myjetbrains.com/youtrack/issue/V-6395/Generate-new-API-keys-for-reCAPTCHA-Enterprise-API
// Configuration `v3 Voluum Panel` `https://www.google.com/u/1/recaptcha/admin/site/716862527`
// Project `Voluum Panel` on Google Cloud `https://console.cloud.google.com/security/recaptcha`
const CAPTCHA_KEY = "6Lc_dLoqAAAAAMExxoJ8oAgvVREKRdOVWw7xaY8e";
let googleClient = null;

const loginDetails = reactive({
    email: "",
    password: "",
    mfaCode: "",
    deviceId: "",
    rememberDevice: false,
});

const isRequestPending = ref(false);
const showMfa = ref(false);
const errorMessage = ref("");
const infoMessage = ref("");
const maintenanceNote = ref("");
const showGoogleBtn = ref(false);
const version = ref({});

onBeforeMount(() => {
    const searchParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf("?")));
    const email = searchParams.get("email");

    if (email) {
        loginDetails.email = email;
    }
});

function submitForm(values) {
    isRequestPending.value = true;
    loginDetails.email = values.email;
    loginDetails.password = values.password;
    AnalyticsService.pushDataLayerEvent("Sign_in_init", "default");
    sendLoginData();
}
function sendLoginData() {
    try {
        grecaptcha.ready(() => {
            grecaptcha.execute(CAPTCHA_KEY, { action: "submit" }).then(
                (token) => {
                    LoginService.createSession(
                        loginDetails.email,
                        loginDetails.password,
                        token,
                        loginDetails.deviceId,
                        loginDetails.rememberDevice,
                        loginDetails.mfaCode,
                    ).then(
                        (response) => {
                            handleSessionCreateResponse(response);
                            isRequestPending.value = false;
                        },
                        (response) => {
                            // If there is no response, log more details to the console
                            if (!response?.response) {
                                console.error(response);
                            }

                            // Checking MFA activation state on catching status '401' with code 'MFA_CODE_NOT_PROVIDED'
                            // task: https://codewise.myjetbrains.com/youtrack/issue/V-5609/Disallow-user-account-enumeration
                            if (response?.response?.status === 401 && response?.response?.data?.error?.code === `MFA_CODE_NOT_PROVIDED`) {
                                let deviceId = $cookies.get(getDeviceIdCookieName());
                                if (deviceId) {
                                    loginDetails.deviceId = deviceId;
                                    sendLoginData();
                                } else {
                                    showMfa.value = true;
                                    isRequestPending.value = false;
                                    setTimeout(() => document.querySelector("input").focus(), 50);
                                }
                            } else {
                                onAuthorizationFailure(response);
                            }
                        },
                    );
                },
                () => handleGRecaptchaError(),
            );
        });
    } catch (_) {
        handleGRecaptchaError();
    }
}
function handleGRecaptchaError() {
    LoginService.createSession(
        loginDetails.email,
        loginDetails.password,
        null,
        loginDetails.deviceId,
        loginDetails.rememberDevice,
        loginDetails.mfaCode,
    ).then(
        (response) => {
            handleSessionCreateResponse(response);
        },
        (response) => {
            onAuthorizationFailure(response);
        },
    );
}
function googleLogin() {
    AnalyticsService.pushDataLayerEvent("Sign_in_init", "Google");
    googleClient.requestCode();
}
function handleSessionCreateResponse(response, firstLogin = false) {
    afterLoginAction(response, firstLogin);
}
function afterLoginAction(response, firstLogin) {
    if (response.status === 200 && response.data.token) {
        let searchParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf("?")));
        $cookies.set(version.value.tokenCookieName, response.data.token, "30d", "/", window.location.hostname);

        if (response.data.deviceId) {
            let expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 28);
            $cookies.set(getDeviceIdCookieName(), response.data.deviceId, expirationDate, "/", window.location.hostname);
        }

        // Add "v" param used to forcefully fetch new index.html
        // (this is needed for scenario when user has old index.html cached in the browser - which generally
        //  should NOT happen, but due to deployment rewrite it might till end of April 2025)
        // See: https://codewise.myjetbrains.com/youtrack/issue/V-5786/Mitigate-cached-index.html-impact
        let redirectUrl = "/?v=1#?login=success";

        if (firstLogin) {
            redirectUrl += "&firstLogin=true";
        }

        if (searchParams.has("redirectTo")) {
            redirectUrl += `&redirectTo=${searchParams.get("redirectTo")}`;
        }

        window.location = redirectUrl;
    }
}
function submitMfaForm(values) {
    loginDetails.mfaCode = values.mfa;
    AnalyticsService.pushDataLayerEvent("Sign_in_init", "default_with_mfa");
    loginDetails.deviceId = null;
    isRequestPending.value = true;
    sendLoginData();
}
function onAuthorizationFailure(response) {
    $cookies.remove(getDeviceIdCookieName(), "/", window.location.hostname);
    loginDetails.password = "";
    loginDetails.deviceId = "";
    loginDetails.mfaCode = "";
    showMfa.value = false;
    isRequestPending.value = false;
    setTimeout(() => document.querySelector("input#password").focus(), 50);
    try {
        errorMessage.value = LoginService.getReadableErrorMeassage(response.response.data.error.code);
    } catch (_) {
        errorMessage.value = "An error has occurred. Please, try again.";
    }
}
function getDeviceIdCookieName() {
    return `_did_${loginDetails.email}`;
}
function tryAutoLogin() {
    let searchParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf("?")));
    let email = searchParams.get("email");
    if (email) {
        loginDetails.email = email;
    }
    let code = searchParams.get("verificationCode");
    if (!code) {
        return;
    }
    let login = searchParams.get("login");
    if (login) {
        loginDetails.email = login;
    }

    infoMessage.value = LoginService.getInfoMsg("ACTIVATING");

    isRequestPending.value = true;
    LoginService.doFirstLogin(code).then(
        (response) => {
            // Just checking if the /profile is available
            LoginService.getUserProfile(response.data.token).then(
                () => {
                    handleSessionCreateResponse(response, true);
                },
                () => {
                    errorMessage.value = LoginService.getReadableErrorMeassage("AUTO_LOGIN_FAILED");
                    infoMessage.value = null;
                    isRequestPending.value = false;
                },
            );
        },
        () => {
            errorMessage.value = LoginService.getReadableErrorMeassage("ACCOUNT_ACTIVATION_FAIL");
            infoMessage.value = null;
            isRequestPending.value = false;
        },
    );
}
function handleNotificationClick(e) {
    if (e.target.matches(".resend-verification-link")) {
        isRequestPending.value = true;
        LoginService.resentVerificationEmail(loginDetails.email).then(
            (_) => {
                isRequestPending.value = false;
                errorMessage.value = "";
                infoMessage.value = `We've resent you verification email. Please, check your Inbox.`;
            },
            () => {
                errorMessage.value = LoginService.getReadableErrorMeassage("ACTIVATION_RESEND_FAIL");
                infoMessage.value = null;
                isRequestPending.value = false;
            },
        );
    }
}
function initGAuth() {
    // register callback that will be fired after loading GIS script
    window.onGoogleLibraryLoad = () => {
        googleClient = google.accounts.oauth2.initCodeClient({
            client_id: window.APP_CONFIG.gauthClientId,
            scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
            ux_mode: "popup",
            callback: (auth) => {
                try {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(CAPTCHA_KEY, { action: "submit" }).then(function (token) {
                            LoginService.createSessionWithOAuth(auth.code, token).then((response) => {
                                $cookies.set("cw-token-cookie", "google", "30d", "/", window.location.hostname);
                                handleSessionCreateResponse(response);
                            });
                        });
                    });
                } catch (_) {
                    LoginService.createSessionWithOAuth(auth.code, null).then((response) => {
                        $cookies.set("cw-token-cookie", "google", "30d", "/", window.location.hostname);
                        handleSessionCreateResponse(response);
                    });
                }
            },
        });
        showGoogleBtn.value = true;
    };

    // append GIS script
    let scriptTag = document.createElement("script");
    scriptTag.src = "https://accounts.google.com/gsi/client";
    scriptTag.async = true;
    scriptTag.defer = true;
    document.head.appendChild(scriptTag);
}

onMounted(() => {
    initGAuth();
    version.value = LoginVersionService.getData();
    maintenanceNote.value = window.APP_CONFIG.maintenanceNotification && window.APP_CONFIG.maintenanceNotification.loginPage;

    let searchParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf("?")));

    if (searchParams.get("planCodeToReview")) {
        $cookies.set("cw-plan-code-to-review", searchParams.get("planCodeToReview"), null, "/", window.location.hostname);
    }
    let redirectReason = searchParams.get("reason");
    if (redirectReason === "passwordChangedSuccessfully") {
        infoMessage.value = LoginService.getInfoMsg("PASSWORD_CHANGED_SUCCESSFULLY");
    } else if (redirectReason === "emailChangedSuccessfully") {
        infoMessage.value = LoginService.getInfoMsg("EMAIL_CHANGE_SUCCESS");
    } else if (redirectReason === "settingPasswordFailed") {
        errorMessage.value = LoginService.getReadableErrorMeassage("INVITATION_USED");
    } else if (redirectReason === "invitationAcceptingFailed") {
        errorMessage.value = LoginService.getReadableErrorMeassage("INVITATION_FAILURE");
    }
    tryAutoLogin();
    // setTimeout(() => document.querySelector("input").focus(), 50);
});
</script>
<style scoped lang="less">
@import "styles/common.less";

.msg-panel {
    .grid-block(@margin-bottom: 6, @max-width: 100);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .msg {
        .grid-block(@padding: 4 4 3);
        flex-direction: row;
        display: flex;

        &.error {
            background: #ffe8ed;

            i {
                color: #f73b5d;
            }
        }

        &.info {
            background-color: @green-lightest;

            i {
                color: @green;
            }
        }

        &.maintenance {
            background-color: #fdf9e5;

            i {
                color: #f7d53b;
            }
        }
    }

    .message {
        .grid-block(@margin-top: 0; @padding: 0.5 2);
        font-family: @open-sans-font-family;
        font-size: 12px;
        line-height: 1.8;
        letter-spacing: 0.4px;
        word-break: break-word;
        color: @dark-blue;

        a {
            color: @dark-blue;
            text-decoration: underline;
        }
    }
}

.login-header {
    .grid-block(@margin-bottom: 8);
    font-family: @montserrat-font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: @dark-blue;
    text-align: center;

    &.mfa-header {
        .grid-block(@margin-bottom: 15);
    }
}

.subheader {
    .grid-block(@margin-bottom: 8);
    font-family: @roboto-font-family;
    font-size: 14px;
    color: @dark-blue;
    letter-spacing: 0.5px;
}

.login-body {
    .grid-block(@margin-bottom: 15, @margin-top: -14);
    width: 62%;
    padding-top: 16vh;

    a {
        font-family: @roboto-font-family;
        font-size: 13px;
        line-height: 1.8;
        text-align: right;
        color: @green;
    }
}

.login-content {
    .grid-block(@max-width: 100);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        width: 100%;
    }
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.oauth-separator {
    .grid-block(@margin: 6 0);
    width: 100%;
    text-align: center;
    position: relative;

    &:before {
        display: block;
        position: absolute;
        content: "";
        height: 1px;
        top: 50%;
        width: 100%;
        background: #d8d8d8;
    }

    .label {
        .grid-block(@padding: 0 3);
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        color: rgba(31, 44, 71, 0.4);
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        background: #fff;
    }
}

.google-sign-in {
    .grid-block(@height: 10; @padding: 2);
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    color: #757575;
    border-radius: 2px;
    background-color: @white;
    font-family: @open-sans-font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: none;
    border: 1px solid rgba(194, 201, 214, 0.6);
    cursor: pointer;

    &:before {
        .grid-block(@width: 4.5; @height: 4.5; @margin-right: 3);
        display: inline-block;
        position: relative;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23EA4335' d='M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z'/%3E%3Cpath fill='%234285F4' d='M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z'/%3E%3Cpath fill='%23FBBC05' d='M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z'/%3E%3Cpath fill='%2334A853' d='M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z'/%3E%3Cpath d='M0 0h18v18H0z'/%3E%3C/g%3E%3C/svg%3E");
    }
}

.row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.sign-in-btn {
    .grid-block(@margin-top: 6);
}

.no-account-info {
    .grid-block(@margin-top: 6);
    font-family: @open-sans-font-family;
    color: @dark-blue;
    font-size: 13px;

    a {
        font-family: @open-sans-font-family;
    }
}

@media (min-width: 1100px) {
    .msg-panel {
        .grid-block(@min-height: 24; @max-width: 100);
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .login-body {
        .grid-block(@margin-top: 18; @margin-bottom: 6);
        width: 100%;
        padding: 6vh 16px 0;
        min-height: 100vh;
    }
}

@media (max-width: 520px) {
    .login-header {
        font-size: 20px;
    }
}
</style>
