export default {
    _version: null,
    _versions: null,

    getData() {
        const noAccountLink = window.APP_CONFIG.frontend.pricing;

        return {
            logos: ["voluum"],
            logoLink: "https://voluum.com",
            tokenCookieName: "cw-token-cookie",
            noAccountLabel: "Don’t have a Voluum account?",
            noAccountLink: noAccountLink,
            noAccountLinkText: "Select a plan and register",
        };
    },
};
