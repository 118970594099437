/**
 * Checks if the input string contains a consecutive sequence of characters of minimum length.
 * Check is performed for normal and reversed sequences.
 * @param input input string
 * @param sequences array of sequences to check
 * @param minSequenceLength minimum length of the sequence that should be found
 * @param isCaseSensitive flag to indicate if the search should be case-sensitive
 */
export function hasConsecutiveCharactersSequence(input, sequences, minSequenceLength = 3, isCaseSensitive = true) {
    const reversedSequence = sequences.map((sequence) => sequence.split("").reverse().join(""));

    if (!input) {
        return false;
    }

    for (const sequence of sequences.concat(reversedSequence)) {
        for (let i = 0; i < input.length - minSequenceLength + 1; ++i) {
            const chunk = input.substring(i, i + minSequenceLength);

            if (isCaseSensitive && sequence.includes(chunk)) {
                return true;
            } else if (!isCaseSensitive && sequence.toLowerCase().includes(chunk.toLowerCase())) {
                return true;
            }
        }
    }

    return false;
}
