<template>
    <label class="checkbox" :for="id">
        <input :id="id" v-model="model" class="checkbox-input" type="checkbox" :data-gtm="gtm" :name="id" />
        <span class="checkbox-text"><slot></slot></span>
    </label>
</template>

<script setup>
const model = defineModel({ type: Boolean });
defineProps({
    className: {
        type: String,
        required: false,
    },
    id: {
        type: String,
        required: false,
    },
    gtm: {
        type: String,
        required: false,
    },
});
</script>
<style scoped lang="less">
@import "styles/common.less";

.checkbox {
    .grid-block(@padding: 4 0);
    display: flex;
    align-items: center;
    font-family: @open-sans-font-family;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: @deep-blue;
    outline: 0;
    white-space: nowrap;

    a {
        color: @deep-blue;
        text-decoration: underline;
    }

    input {
        .grid-block(@width: 5; @min-width: 5; @height: 5; @padding: 0; @margin-left: 0);
        -webkit-appearance: none;
        margin-right: 1em;
        display: inline-block;
        vertical-align: top;
        position: relative;
        outline: 0;
        border: solid 1px @grey-border;
        transition: border-color 0.1s ease-in;

        &:focus {
            border-width: 1px;
        }

        &:before {
            .grid-block(@top: 0.75; @left: 0.75; @width: 3; @height: 3);
            content: "";
            position: absolute;
            background: transparent;
        }

        &:checked:before {
            background: @green;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            border-color: @dark-blue;
        }
    }
}
</style>
