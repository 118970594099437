<template>
    <div class="login-container">
        <div class="logo">
            <a :href="version.logoLink" target="_blank">
                <div v-for="logo in version.logos" :class="['logo-img', logo]"></div>
            </a>
            <a class="support-btn" href="https://doc.voluum.com/#support" target="_blank">SUPPORT</a>
        </div>
        <router-view />
        <CwNewsfeed></CwNewsfeed>
    </div>
</template>

<script>
import CwNewsfeed from "./components/CwNewsfeed.vue";
import AnalyticsService from "./services/AnalyticsService";
import LoginVersionService from "./services/LoginVersionService";

export default {
    name: "App",
    components: {
        CwNewsfeed,
    },
    data: () => ({
        version: {},
    }),
    mounted: function () {
        AnalyticsService.setupAnalytics();
        this.version = LoginVersionService.getData();
    },
};
</script>
<style lang="less">
@import "components/styles/common.less";
@import "components/styles/fonts.css";

body {
    min-height: 100%;
    margin: 0;
}

.login-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.logo {
    .grid-block(@top: 0, @left: 0);

    position: absolute;

    .logo-img {
        position: relative;

        &:before {
            .grid-block(@height: 12; @width: 35; @top: 6; @left: 7);

            display: block;
            position: absolute;
            content: "";
        }

        &.voluum:before {
            background: url("assets/logo.svg") no-repeat center;
            background-size: 100% 100%;
        }

        &.txt:before {
            background: url("assets/logo-txt.svg") no-repeat center;
            background-size: 140px;
            width: 160px;
        }
    }
}

.support-btn {
    .grid-block(@padding-left: 6; @top: 7; @right: 4);
    font-family: @montserrat-font-family;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.1px;
    color: @deep-blue;
    position: absolute;
    display: none;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 1100px) {
    .login-container {
        flex-direction: column;
    }
    .logo {
        .grid-block(@height: 18);
        border-bottom: 1px solid #c2c9d6;
        width: 100%;

        .logo-img:before {
            .grid-block(@width: 30; @height: 10; @top: 4; @left: 4);
        }
    }
    .support-btn {
        display: block;
    }
}
</style>
