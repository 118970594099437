/**
 * Returns true if the input string contains a sequence of characters that are repeated given amount of times.
 * @param input input string
 * @param minSequenceLength minimum length of the repeated characters that should be found
 */
export function hasRepeatedCharacters(input, minSequenceLength = 3) {
    if (!input) {
        return false;
    }

    for (let i = 0; i < input.length - minSequenceLength + 1; ++i) {
        const chunk = input.substring(i, i + minSequenceLength);

        if (chunk === chunk[0].repeat(minSequenceLength)) {
            return true;
        }
    }

    return false;
}
