<template>
    <div
        :class="{
            'text-field': true,
            'validation-error': !!errorMessage,
            'text-field--disabled': disabled,
            inspectable: inspectable,
        }"
        :data-cy="cy"
    >
        <div class="input-wrapper">
            <transition name="input-info-fade">
                <button v-if="!!errorMessage" class="icon validation-error" type="button" tabindex="-1">
                    <i v-if="!!errorMessage" class="material-icons" tabindex="-1">error_outline</i>
                </button>
            </transition>
            <input
                :id="id"
                data-cy="input"
                v-model="inputValue"
                class="input"
                required
                :name="id"
                :type="inputType"
                :value="inputValue"
                :disabled="disabled"
                :data-gtm="gtm"
                :autocomplete="autocomplete"
                :inputmode="inputMode"
                :autofocus="autofocus"
                @blur="handleChange"
                @keyup.enter="$emit('keyup.enter', handleBlur)"
            />
            <label :for="name" :class="['floating-label', hasText]" tabindex="-1">{{ placeholder }}</label>
            <template v-if="inspectable">
                <i v-if="!isPasswordVisible" class="password-visible-toggle material-icons" tabindex="-1" @click="isPasswordVisible = true"
                    >visibility_off</i
                >
                <i v-if="isPasswordVisible" class="password-visible-toggle material-icons" tabindex="-1" @click="isPasswordVisible = false"
                    >visibility</i
                >
            </template>
        </div>
        <div class="info-wrapper">
            <transition name="input-info-fade">
                <p v-if="!!errorMessage" class="info" tabindex="-1">
                    <span class="info-text" data-cy="error">{{ errorMessage }}</span>
                </p>
            </transition>
        </div>
    </div>
</template>

<script setup>
import { useField } from "vee-validate";
import { computed, toRef, ref } from "vue";

const props = defineProps({
    placeholder: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
    initValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
    gtm: {
        type: String,
        required: false,
    },
    autocomplete: {
        type: String,
        required: false,
    },
    inputMode: {
        type: String,
        required: false,
    },
    id: {
        type: String,
        required: true,
    },
    cy: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    inspectable: {
        type: Boolean,
        required: false,
        default: false,
    },
    bails: {
        type: Boolean,
        required: false,
        default: true,
    },
});
defineEmits(["blur", "keyup.enter"]);

const name = toRef(props, "name");
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(name, undefined, {
    initialValue: props.initValue,
    bails: props.bails,
});

const isPasswordVisible = ref(false);
const reactiveInputValue = ref(inputValue);
const hasText = computed(() => (reactiveInputValue.value && reactiveInputValue.value.length > 0 ? "has-content" : ""));
const inputType = computed(() => (isPasswordVisible.value ? "text" : props.type));
</script>
<style scoped lang="less">
@import "styles/common.less";

@input-icon-color: rgba(#1e2945, 0.3);

.text-field {
    .grid-block(@margin-bottom: 10);
    display: block;
    width: 100%;
    position: relative;

    .input-wrapper {
        .grid-block(@height: 12; @min-height: 12; @max-height: 12);
        text-align: left;
    }

    .input {
        .grid-block(@padding: 3 11.25 3 4; @height: 12; @min-height: 12; @max-height: 12);
        width: 100%;
        box-sizing: border-box;
        display: block;
        border-radius: 1px;
        border: 1px solid @grey-border;
        background: @white;
        box-shadow: none;
        outline: 0;
        font-family: @open-sans-font-family;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.2px;
        color: @dark-blue;
        transition: border-color 0.1s ease-in;

        &:hover {
            border-color: @dark-blue;
        }

        &:focus {
            border-color: transparent;
            outline: 2px solid @purple;

            & + .floating-label {
                transform: translate3d(12px, -62px, 0) scale(0.8);
                transition: 0.1s transform linear;
                color: @purple;
            }
        }

        // autofill color and label fix
        @-webkit-keyframes autofill {
            to {
                color: #666;
                background: transparent;
            }
        }

        &:-webkit-autofill {
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
            background: @white;

            & + .floating-label {
                transform: translate3d(12px, -62px, 0) scale(0.8);
                transition: 0.1s transform linear;
            }
        }
    }

    .floating-label {
        display: inline-block;
        font-family: @open-sans-font-family;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        color: @deep-blue;
        transform: translate3d(14px, -38px, 0);
        background-color: @white;
        padding: 2px 5px;
        transform-origin: left;
        transition: 0.1s transform linear;
        outline: 0;

        &:hover {
            cursor: text;
        }

        &.has-content {
            transform: translate3d(12px, -62px, 0) scale(0.8);
            transition: 0.1s transform linear;
        }
    }

    .icon {
        .grid-block(@padding: 0; @margin-bottom: 0; @height: 6; @width: 6; @right: 4);
        position: absolute;
        top: calc(50% - 12px);
        box-shadow: none;
        outline: 0;
        border: 0;
        background-color: transparent;

        & > i {
            color: @input-icon-color;
            outline: 0;
        }

        &--validation-error {
            & > i {
                color: @input-icon-color;
            }
        }
    }

    .info-wrapper {
        outline: none;
        max-height: 0;
        transition: max-height 0.2s ease-in-out;

        p {
            outline: none;
        }
    }

    .info {
        font-family: @open-sans-font-family;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: @validation-error-red;
        margin: 0;
        padding: 8px 15px 0 15px;
    }

    .info-text {
        vertical-align: text-top;
    }

    &.validation-error {
        .input {
            border-color: @validation-error-red;
            transition: border-color 0.1s ease-in;

            &:hover {
                border-color: #cc3349;
            }

            &:focus {
                outline: 2px solid @validation-error-red;

                & + label.floating-label {
                    color: @validation-error-red;
                }
            }
        }

        .icon.validation-error {
            & > i {
                color: @validation-error-red;
            }
        }

        label.floating-label {
            color: @validation-error-red;
        }

        div.info-wrapper {
            transition: max-height 0.2s ease-in-out;
        }

        &.inspectable {
            .input {
                .grid-block(@padding-right: 17);
            }

            .icon.validation-error {
                .grid-block(@right: 10);
            }
        }
    }

    .password-visible-toggle {
        .grid-block(@right: 2.5);

        cursor: pointer;
        position: absolute;
        top: calc(50% - 12px);
        color: #bcc1cb;

        &:hover {
            color: #6a768c;
        }
    }
}
</style>
