/**
 * Check if input contains any character from any of the sequences passed.
 * @param input input string
 * @param sequences character sequence to based on
 */
export function hasAnyCharacterFromSequence(input, sequences) {
    if (!input) {
        return false;
    }

    for (const sequence of sequences) {
        for (const char of input) {
            if (sequence.indexOf(char) !== -1) {
                return true;
            }
        }
    }

    return false;
}
