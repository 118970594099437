<template>
    <div :data-cy="cy" :class="['btn', loaderClass, className]" type="button" :data-gtm="gtm" @click="$emit('click')">
        <img v-show="isLoader" src="@/assets/loader.svg" alt="" />
        <span v-show="!isLoader" class="text">{{ label }}</span>
    </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
    cy: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
    gtm: {
        type: String,
        required: false,
    },
    isLoader: {
        type: Boolean,
        required: false,
    },
    label: {
        type: String,
        required: true,
    },
    className: {
        type: String,
        required: false,
    },
});
defineEmits(["click"]);

const loaderClass = computed(() => (props.isLoader ? "loading" : ""));
</script>
<style lang="less">
@import "styles/common.less";

.btn {
    .grid-block(@padding: 4 0; @height: 12);
    font-family: @montserrat-font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 1.3px;
    text-align: center;
    text-transform: uppercase;
    color: @dark-blue;
    width: 100%;
    border-radius: 1px;
    border: 0;
    background: linear-gradient(to top, #00f6ab, #54ffc1) transparent;
    outline: 0;
    box-shadow: none;
    text-decoration: none;
    transition: 0.2s background-image ease-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    z-index: 1;

    &:before {
        .grid-block(@width: 75; @height: 75);
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        background: rgba(0, 8, 116, 0.16);
        pointer-events: none;
        transform: translate(-50%, -50%) scale(1, 1);
        opacity: 0;
        transition:
            transform 1s,
            opacity 1s;
    }

    .text {
        font-family: @montserrat-font-family;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 1.25px;
        text-align: center;
        color: @dark-blue;
        text-transform: none;
    }

    &:active:before {
        transform: translate(-50%, -50%) scale(0, 0);
        opacity: 1;
        transition: 0s;
    }

    &:hover {
        cursor: pointer;
    }

    &.loading {
        background: @green-lightest;

        &:hover,
        &:focus,
        &:active {
            &:before {
                display: none;
                cursor: none;
            }
        }

        &:hover {
            cursor: auto;
        }
    }
}
</style>
