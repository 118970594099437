import http from "../helpers/http";
export default {
    createSession(email, password, captchaToken, deviceId, rememberDevice, mfaCode) {
        const sessionPaylod = { email, password, rememberDevice, deviceId, captchaToken };
        if (mfaCode) {
            sessionPaylod.mfaCode = mfaCode;
        }
        return http.post(`${window.APP_CONFIG.server.portal}/auth/session`, sessionPaylod);
    },

    createSessionWithOAuth(token, captchaToken) {
        return http.post(`${window.APP_CONFIG.server.portal}/auth/session/oauth`, {
            authorizationCode: token,
            redirectUri: window.location.origin,
            captchaToken: captchaToken,
        });
    },

    doFirstLogin(code) {
        return http.post(`${window.APP_CONFIG.server.portal}/profile/registration/${code}/firstLogin`);
    },

    getUserProfile(token) {
        return http.get(`${window.APP_CONFIG.server.portal}/profile`, { headers: { "cwauth-token": token } });
    },

    resentVerificationEmail(email) {
        return http.post(`${window.APP_CONFIG.server.portal}//profile/registration/resend`, { email });
    },

    resetPasswordInit(email) {
        return http.post(`${window.APP_CONFIG.server.portal}/profile/passwordreset/init`, { email });
    },

    setNewPassword(code, newPassword) {
        return http.post(`${window.APP_CONFIG.server.portal}/profile/passwordreset/execute`, { code, newPassword });
    },

    getReadableErrorMeassage(errorCode, messages = []) {
        // If password validation failed on BE, return the first error message that should
        // contain relevant information about the password validation error.
        if (errorCode === "PASSWORD_POLICY_VIOLATION" && messages?.length > 0) {
            return messages[0];
        }

        return errorMsgs[errorCode]
            ? errorMsgs[errorCode]
            : 'An error has occurred. Please, try again or <a href="https://doc.voluum.com/#support" target="_blank">contact the support</a>.';
    },

    getInfoMsg(code) {
        return infoMsgs[code];
    },
};

const errorMsgs = {
    BAD_CREDENTIALS: "Invalid user credentials - please try again.",
    ACCOUNT_NOT_VERIFIED:
        'Your email has not been verified. Please click on the link in the confirmation email we sent you. <a href="#" class="resend-verification-link">Click here</a> to re-send the verification link.',
    ACCOUNT_INACTIVE: "Thank you for completing your registration!<br/>You will receive an email once we activate your account",
    ACCOUNT_DISABLED: "Too many invalid login attempts - your account has been locked for 30 seconds.",
    ACCOUNT_DELETED: 'Account deleted. Please contact <a href="https://doc.voluum.com/#support" target="_blank">our support</a>.',
    ACCOUNT_SUSPENDED:
        'Your account has been suspended. Please contact <a href="https://doc.voluum.com/#support" target="_blank">our support</a>.',
    AUTO_LOGIN_FAILED: "Your account was activated but automatically login failed. Try login manually.",
    ACCOUNT_ACTIVATION_FAIL:
        'Something went wrong. Refresh page to try again or contact <a href="https://doc.voluum.com/#support">our support</a>.',
    ACTIVATION_RESEND_FAIL:
        'You cannot resend activation email more than 10 times. Please contact <a href="https://doc.voluum.com/#support">support</a>.',
    INVITATION_USED: "Your invitation link is incorrect or has been used before.",
    INVITATION_FAILURE: "Your invitation link is incorrect or has been used before.",
};

const infoMsgs = {
    ACTIVATING: "We are activating your account. Then you will be logged in automatically.",
    PASSWORD_CHANGED_SUCCESSFULLY: "Password has been changed successfully.",
    EMAIL_CHANGE_SUCCESS: "Email changed successfully.",
};
