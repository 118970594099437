<template>
    <div class="reset-password-body">
        <div class="msg-panel">
            <div v-if="errorMessage" data-cy="error-message-panel" class="msg error">
                <div class="icon-wrapper">
                    <i class="material-icons" tabindex="-1">error_outline</i>
                </div>
                <div class="message" v-html="errorMessage"></div>
            </div>
            <div v-if="infoMessage && !errorMessage" data-cy="info-message-panel" class="msg info">
                <div class="icon-wrapper">
                    <i class="material-icons" tabindex="-1">check_circle_outline</i>
                </div>
                <div class="message">{{ infoMessage }}</div>
            </div>
        </div>
        <div class="reset-password-header">Reset your password</div>
        <VeeForm v-if="!data.showSetPassword" v-slot="{ values, handleSubmit }" as="div" class="content" :validation-schema="emailSchema">
            <div class="subheader">
                We'll send you a reset link. If you don't have access to your email
                <a href="https://doc.voluum.com/#support" target="_blank">contact us</a>.
            </div>

            <CwInput
                id="email"
                cy="email"
                name="email"
                placeholder="Email"
                type="email"
                gtm="reset_pwd_email_input"
                autocomplete="email"
                input-mode="email"
                :initValue="data.email"
                @keyup.enter="handleSubmit($event, submitForm)"
            />

            <CwButton
                id="resetPassword"
                cy="reset-password-button"
                gtm="reset_password"
                label="RESET PASSWORD"
                :is-loader="isRequestPending"
                @click="handleSubmit($event, submitForm)"
            >
            </CwButton>
            <div class="no-account-info">
                <a data-cy="log-in-link">
                    <router-link
                        :to="{
                            path: '/',
                            query: { email: values.email },
                        }"
                    >
                        Return to sign-in page
                    </router-link>
                </a>
            </div>
        </VeeForm>
        <VeeForm v-if="data.showSetPassword" v-slot="{ handleSubmit, errorBag, meta }" class="content" :validation-schema="passwordSchema">
            <div class="subheader">Please type in new password.</div>

            <CwInput
                id="password"
                cy="password"
                name="password"
                placeholder="New password"
                type="password"
                gtm="new_pwd_input"
                input-mode="password"
                :inspectable="true"
                :bails="false"
                @keyup.enter="handleSubmit($event, submitSetPassword)"
            />
            <div class="password-requirements">
                <ul>
                    <li>
                        <CwValidationRuleStatus
                            text="length between 8 and 64 characters"
                            :is-valid="meta.dirty && !hasValidatorFailed('min-max', errorBag.password)"
                            cy="length-rule-status"
                        />
                    </li>
                    <li>
                        <CwValidationRuleStatus
                            text="uppercase"
                            :is-valid="meta.dirty && !hasValidatorFailed('hasUppercaseLetter', errorBag.password)"
                            cy="uppercase-rule-status"
                        />
                    </li>
                    <li>
                        <CwValidationRuleStatus
                            text="lowercase"
                            :is-valid="meta.dirty && !hasValidatorFailed('hasLowercaseLetter', errorBag.password)"
                            cy="lowercase-rule-status"
                        />
                    </li>
                </ul>
                <ul>
                    <li>
                        <CwValidationRuleStatus
                            text="number"
                            :is-valid="meta.dirty && !hasValidatorFailed('hasDigit', errorBag.password)"
                            cy="number-rule-status"
                        />
                    </li>
                    <li>
                        <CwValidationRuleStatus
                            text="special character"
                            :is-valid="meta.dirty && !hasValidatorFailed('hasSpecialCharacter', errorBag.password)"
                            cy="special-rule-status"
                        />
                    </li>
                </ul>
            </div>

            <CwButton
                id="setPassword"
                cy="set-password-button"
                gtm="set_password"
                label="SET PASSWORD"
                :is-loader="isRequestPending"
                @click="handleSubmit($event, submitSetPassword)"
            >
            </CwButton>
            <div class="no-account-info">
                <a data-cy="log-in-link">
                    <router-link to="/">Return to sign-in page</router-link>
                </a>
            </div>
        </VeeForm>
    </div>
</template>

<script setup>
import CwInput from "./CwInput.vue";
import CwButton from "./CwButton.vue";
import CwValidationRuleStatus from "./CwValidationRuleStatus.vue";
import LoginService from "../services/LoginService";
import AnalyticsService from "../services/AnalyticsService";
import { reactive, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { Form as VeeForm } from "vee-validate";

const router = useRouter();

const data = reactive({
    email: "",
    loginLink: "",
    showSetPassword: false,
    code: "",
    newPassword: "",
});

const isRequestPending = ref(false);
const errorMessage = ref("");
const infoMessage = ref("");

const emailSchema = {
    email: "required|email",
};
const passwordSchema = {
    password:
        "min-max:8,64|hasUppercaseLetter|hasLowercaseLetter|hasDigit|hasSpecialCharacter|hasNotAlphabeticalSequence:3|hasNotDigitsSequence:3|hasNotUSKeyboardSequence:4|hasNotRepeatedCharacters:3",
};

onBeforeMount(() => {
    let searchParams = new URLSearchParams(window.location.href.slice(window.location.href.indexOf("?")));
    let code = searchParams.get("code");

    if (code) {
        data.code = code;
        data.showSetPassword = true;
    } else {
        let email = searchParams.get("email");

        if (email) {
            data.email = email;
        }
    }

    setTimeout(() => document.querySelector("input").focus(), 50);
});

function submitForm(values) {
    data.email = values.email;
    isRequestPending.value = true;

    AnalyticsService.pushDataLayerEvent("reset_password", "default");
    LoginService.resetPasswordInit(values.email).then(
        (_) => {
            isRequestPending.value = false;
            infoMessage.value = `Email with password reset link was sent to ${values.email}. If you haven't received it please check your Spam folder and make sure that you used the correct e-mail address connected with your Voluum account.`;
            errorMessage.value = "";
        },
        (response) => {
            onRequestFail(response);
        },
    );
}

function submitSetPassword(values) {
    data.newPassword = values.password;
    isRequestPending.value = true;

    AnalyticsService.pushDataLayerEvent("set_password", "default");
    LoginService.setNewPassword(data.code, data.newPassword).then(
        (_) => {
            isRequestPending.value = false;
            errorMessage.value = "";
            router.push({ path: `/?reason=passwordChangedSuccessfully` }); // -> /user/123
        },
        (response) => {
            onRequestFail(response);
        },
    );
}

function onRequestFail(response) {
    isRequestPending.value = false;
    infoMessage.value = "";
    try {
        errorMessage.value = LoginService.getReadableErrorMeassage(
            response.response.data.error.code,
            response.response.data.error.messages,
        );
    } catch (_) {
        errorMessage.value =
            'An error has occurred. Please, try again or <a href="https://doc.voluum.com/#support" target="_blank">contact the support</a>.';
    }
}

function hasValidatorFailed(validatorName, errors) {
    const validatorToMessageMap = {
        "min-max": "This field must contain between 8 and 64 characters.",
        hasUppercaseLetter: "This field must contain at least one uppercase letter.",
        hasLowercaseLetter: "This field must contain at least one lowercase letter.",
        hasDigit: "This field must contain at least one digit.",
        hasSpecialCharacter: "This field must contain at least one special character.",
    };

    return validatorToMessageMap[validatorName] && errors?.includes(validatorToMessageMap[validatorName]);
}
</script>
<style scoped lang="less">
@import "styles/common.less";

.msg-panel {
    .grid-block(@margin-bottom: 6, @max-width: 100);
    margin-left: auto;
    margin-right: auto;

    .msg {
        .grid-block(@padding: 4 4 3);
        flex-direction: row;
        display: flex;

        &.error {
            background: #ffe8ed;

            i {
                color: #f73b5d;
            }
        }

        &.info {
            background-color: @green-lightest;

            i {
                color: @green;
            }
        }
    }

    .message {
        .grid-block(@margin-top: 0; @padding: 0.5 2);
        font-family: @open-sans-font-family;
        font-size: 12px;
        line-height: 1.8;
        letter-spacing: 0.4px;
        word-break: break-word;
        color: @dark-blue;

        a {
            color: @dark-blue;
            text-decoration: underline;
        }
    }
}

.reset-password-header {
    .grid-block(@margin-bottom: 8);
    font-family: @montserrat-font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: @dark-blue;
    text-align: center;
}

.subheader {
    .grid-block(@margin-bottom: 8);
    font-family: @roboto-font-family;
    font-size: 14px;
    text-align: center;
    color: @dark-blue;
    letter-spacing: 0.5px;
}

.reset-password-body {
    .grid-block(@margin-bottom: 15);
    width: 62%;
    margin-top: calc(10vh + 56px);

    a {
        line-height: 1.8;
        text-align: right;
        color: @green;
    }
}

.content {
    .grid-block(@max-width: 100);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        width: 100%;
    }
}

.row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.sign-in-btn {
    .grid-block(@margin-top: 6);
}

.no-account-info {
    .grid-block(@margin-top: 6);
    font-family: @open-sans-font-family;
    color: @dark-blue;
    text-align: center;
    font-size: 13px;
}

.password-requirements {
    .grid-block(@margin-bottom: 3);

    display: flex;
    width: 100%;
}

ul {
    padding: 0;
    margin: 8px 0 0 0;
    list-style: none;

    &:not(:last-child) {
        margin-right: 24px;
    }
}

@media (min-width: 1100px) {
    .msg-panel {
        .grid-block(@min-height: 24; @max-width: 100);
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .reset-password-body {
        .grid-block(@margin-top: 18; @margin-bottom: 15);
        width: 100%;
        padding: 8vh 16px 0;
        min-height: 100vh;
    }
}
</style>
