<template>
    <div class="newsfeed">
        <div class="heading">
            <a href="https://doc.voluum.com/#support" target="_blank">SUPPORT</a>
        </div>
        <div class="news-list">
            <div class="news-list-heading">What's new?</div>
            <div class="news-list-items">
                <article v-for="item in newsfeedItems">
                    <span class="title">
                        {{ item.title }}
                        <span v-if="item.isNew" class="tile new">new</span>
                    </span>
                    <p>
                        {{ item.description }}
                        <a v-if="item.docLink" :href="item.docLink" target="_blank" @click="logArticleClick(item.title)">
                            {{ item.cta ? item.cta : "Read more" }}</a
                        >
                    </p>
                </article>
            </div>
            <div class="gradient-fade-out"></div>
        </div>
        <div class="cookie-note">
            Cookies help us deliver our services. By using our services, you agree to our use of cookies. You can turn cookies off by
            changing your browser settings.
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import http from "../helpers/http";
import AnalyticsService from "../services/AnalyticsService";

function logArticleClick(title) {
    AnalyticsService.pushDataLayerEvent("GAEvent", title, "newsfeed_learn_more");
}

const newsfeedItems = ref([]);

onMounted(async () => {
    newsfeedItems.value = (
        await http.get("https://panel.voluum.com/newsfeed.json", {
            responseType: "json",
        })
    ).data.newsfeedData;
});
</script>
<style lang="less">
@import "styles/common.less";
@background-gradient-purple: linear-gradient(to left, #5753d5, #5a40d0);

.newsfeed {
    .grid-block(@padding: 10 14);
    display: flex;
    width: 38%;
    flex-direction: column;
    background-image: @background-gradient-purple;
    position: relative;

    .heading {
        .grid-block(@height: 4);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        a {
            .grid-block(@padding-left: 6);
            font-family: @montserrat-font-family;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.1px;
            color: rgba(255, 255, 255, 0.6);
            position: relative;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: @white;
            }

            &:before {
                .grid-block(@left: 0; @width: 4; @height: 4);
                display: block;
                position: absolute;
                content: "";
                background: url("../assets/support.svg") no-repeat center;
                background-size: 100% 100%;
            }
        }
    }

    .news-list {
        margin-top: 16vh;
        .news-list-items {
            max-height: 50vh;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
                width: 0;
            }
        }

        .news-list-heading {
            .grid-block(@margin-bottom: 7; @padding-left: 13);
            font-family: @montserrat-font-family;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.25px;
            color: @white;
            position: relative;

            &:before {
                .grid-block(@left: 0; @width: 10; @height: 10; @top: -1.5);
                display: block;
                position: absolute;
                content: "";
                background: url("../assets/rocket.svg") no-repeat center;
                background-size: 100% 100%;
            }
        }

        article {
            .grid-block(@padding-bottom: 7);
            font-family: @montserrat-font-family;
            color: @white;
            position: relative;

            &:last-of-type {
                .grid-block(@padding-bottom: 17.5);
            }

            &:after {
                height: 1px;
                opacity: 0.15;
                background-color: #d8d8d8;
                content: "";
                position: absolute;
                width: 100%;
                display: block;
            }

            span.title {
                display: block;
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: 0.2px;

                &:not(:first-of-type) {
                    .grid-block(@padding-top: 7);
                }

                .tile {
                    .grid-block(@height: 4; @padding: 0 1; @margin-left: 2; @line-height: 4; @top: -0.5);

                    font-family: @roboto-font-family;
                    font-size: 10px;
                    font-weight: 500;
                    border-radius: 2px;
                    display: inline-flex;
                    position: relative;

                    &.new {
                        background-color: #c800a0;
                    }
                }
            }

            p {
                .grid-block(@margin: 5 0 7);
                font-family: @open-sans-font-family;
                font-size: 14px;
                line-height: 1.6;
                letter-spacing: 0.25px;

                a {
                    color: #00d193;
                }
            }
        }
    }

    .gradient-fade-out {
        .grid-block(@height: 18; @margin-top: -18);
        mask-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
        background-image: @background-gradient-purple;
    }

    .cookie-note {
        .grid-block(@bottom: 9; @margin-right: 17; @margin-left: 9);
        font-family: @open-sans-font-family;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: hsla(0, 0%, 100%, 0.4);
        position: absolute;

        &:before {
            .grid-block(@width: 5; @height: 5; @top: 0.5; @left: -9);
            display: block;
            position: absolute;
            content: "";
            background: url("../assets/cookie.svg") no-repeat center;
            background-size: 100% 100%;
        }
    }
}

@media (max-width: 1100px) {
    .newsfeed {
        .grid-block(@padding: 8 6);
        min-height: 100vh;
        width: 100%;
        box-sizing: border-box;

        .heading {
            display: none;
        }

        .news-list {
            margin-top: 0;

            .news-list-items {
                max-height: 60vh;
            }

            article {
                title {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
